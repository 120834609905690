/**
 *
 * @param {{get: (key: string) => boolean}} flags
 * @param {{appContext: {abTestState: string}}} appContext
 * @returns {("marketData" | "highlights" | "articleContext")[]}
 */
export const getActiveFeatures = (flags, appContext) => {
	/** @type {("marketData" | "highlights" | "articleContext")[]}*/
	const features = [];

	const amplitudeFlags = new Map(
		(appContext.appContext?.abTestState ?? '')
			.split(',')
			.map((pair) => pair.split(':'))
			.filter((pair) => pair.length === 2)
			.map(([key, value]) => [key, value])
	);

	if (flags.get('proStocksWidgetArticlePage')) {
		features.push('marketData');
	}

	if (
		flags.get('proArticleContextWidget') &&
		(amplitudeFlags.get('proarticlecontextwidgetabtest') === 'variant-1' ||
			amplitudeFlags.get('proarticlecontextwidgetabtest') === 'variant-2')
	) {
		features.push('articleContext');
	}

	if (flags.get('enterpriseUserAnnotationsEditor')) {
		features.push('highlights');
	}

	return features;
};
